.pokAndPlay__header{
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 8px;
  position: sticky;
  top: 56px;
  z-index: 49;
  height: 60px;
  backdrop-filter: blur(8px) brightness(0.8)
}

.pokAndPlay__container{
  display: flex;
  flex-direction: row;
  height: 100%;
}

.pokAndPlay__container__feed{
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
}

.pokAndPlay__feed{
  flex: 1;
}

.pokAndPlay__header__filters{
  display: flex;
}