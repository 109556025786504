.PokAndPlayFeedLoaders__container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.PokAndPlayFeedLoaders__item{
  height: 200px;
  width: 244px;
  margin: 8px 8px 32px 8px;
}