@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./components/base/Carousel/carousel.css');
@import url('./components/VideoPlayer/custom-style.css');
@import url('./styles/pokAndPlay.css');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_Light_Italic.otf');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_Light.otf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_Regular_Italic.otf');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_Medium_Italic.otf');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_Medium.otf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_SemiBold_Italic.otf');
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_SemiBold.otf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_Bold_Italic.otf');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_Bold.otf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_ExtraBold_Italic.otf');
  font-style: italic;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_ExtraBold.otf');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_Black_Italic.otf');
  font-style: italic;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Aliance';
  src: url('/fonts/Alliance_No_1_Black.otf');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: 'Aliance', 'Inter', sans-serif;
  white-space: pre-line;
  background-color: #141416;
  min-height: 100vh;
  overflow-y: scroll;
}

div#__next {
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 12px;
  border: 3px solid #323232;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #262626;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 3px solid #262626;
  border-radius: 10px;
  background: #323232;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

button:focus {
  outline: none;
}

.timeline {
  display: flex;
  justify-content: center;
  width: 100%;
}

.timeline::after {
  margin-left: -4.5px;
}

.marker-col {
  flex-basis: 70px !important;
  z-index: 30 !important;
}

@media (max-width: 766px) {
  .marker-col {
    margin-left: -15px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* .ReactVirtualized__List {
  overflow: hidden !important;
} */

.ReactVirtualized__List:focus {
  outline: none;
}

.carousel .control-dots {
  top: 0;
}

.carousel-root,
.slider,
.slider-wrapper,
.carousel,
.carousel-slider {
  height: 100%;
}

.MuiSkeleton-root {
  background-color: #353945 !important;
}

td,
th {
  border: 1px solid #353945;
  padding: 8px;
}

.swiper-pagination-bullet {
  background: #777e90 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #26ded0 !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #ffffff !important;
}

.backdrop-blur {
  -webkit-backdrop-filter: blur(8px) brightness(0.3);
  backdrop-filter: blur(8px) brightness(0.3);
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur {
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 30;
  }
}

.\!z-50 {
  z-index: 50 !important;
}

.\!z-40 {
  z-index: 40 !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blurry-text {
  color: transparent;
  text-shadow: 0 0 6px rgba(255, 255, 255, 1);
}

.blurry-text-xl {
  color: transparent;
  text-shadow: 0 0 16px rgba(255, 255, 255, 1);
}

.text-shadow {
  text-shadow: 1px 1px 1px #141416;
}

@import url('./styles/pokAndPlay.css');
@import url('./styles/feed.css');
@import url('./components/index.css');
