.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* hide scrollbar in Firefox */
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.carousel-content.show-2 > * {
  width: calc(50% - 1.6em);
  max-width: calc(50% - 1.6em);
}

.carousel-content.show-3 > * {
  width: calc(100% / 3 - 1.6rem);
  max-width: calc(100% / 3 - 1.6rem);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4 - 1.6rem);
  max-width: calc(100% / 4 - 1.6rem);
}

.left-arrow, .right-arrow {
  outline: none;
  position: absolute;
  z-index: 1;
  top: 58%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: white;
}

/* .left-arrow {
  left: -20px;
}

.right-arrow {
  right: -20px;
} */

.carouselMarginRight {
  margin-right: 0.75em
}

.carouselMarginLeft {
  margin-left: 0.75em
}

@media (hover: none) and (pointer: coarse) {
  .carousel-content.show-1 > * {
    width: 100%;
    margin: 0 !important;
  }

  .carousel-content.show-2 > * {
    width: 100%;
    margin: 0 !important;
  }

  
  .left-arrow {
    left: calc(100% - 115px);
    top: 110%;
  }
  
  .right-arrow {
    right: 20px;
    top: 110%
  }
}
